import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { PayloadSender, State } from 'xstate';
import {
  TContext,
  TEvent,
  TState,
  useDocumentState,
} from '@machines/document.machine';
import { MAX_FILE_SIZE } from '@utils/constants';
import { logToApm } from '@utils/log-error';
import { useRouter } from 'next/router';
import { getSessionId } from '@resources/session';

type UploadContextType =
  | { state: State<TContext, TEvent, any, TState>; send: PayloadSender<TEvent> }
  | undefined;

const DocumentContext = React.createContext<UploadContextType>(undefined);

export const DocumentProvider: React.FC = ({ children }) => {
  const [state, send] = useDocumentState();

  const memoValue = React.useMemo(() => ({ state, send }), [state, send]);
  return (
    <DocumentContext.Provider value={memoValue}>
      {children}
    </DocumentContext.Provider>
  );
};

export const useDocument = (options: DropzoneOptions = {}) => {
  const context = React.useContext(DocumentContext);
  if (context === undefined) {
    throw new Error('useDocument must be used within a DocumentProvider');
  }
  const { push, query } = useRouter();

  const onDropAccepted = React.useCallback(
    async (acceptedFiles) => {
      context.send({ type: 'UPLOAD_INITIATE', document: acceptedFiles[0] });
      try {
        const { data } = await getSessionId();
        const { liveSessionId: sessionId } = data || {};

        if (!sessionId) {
          throw new Error('unable to fetch sessionId');
        }
        push({
          pathname: `/${sessionId}`,
          query,
        });
        context.send({ type: 'UPLOAD_START', sessionId });
      } catch (error) {
        logToApm(error);
      }
    },
    [context, push, query],
  );

  const dropZone = useDropzone({
    accept: [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
    ],
    onDropAccepted,
    multiple: false,
    noDragEventsBubbling: true,
    maxSize: MAX_FILE_SIZE, // 20MB same as service layer
    ...options,
  });

  const isUploading = ['preUpload', 'uploading'].includes(
    context.state.value as string,
  );

  return { ...context, dropZone, isUploading };
};
